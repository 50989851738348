/* eslint-disable no-unused-vars */
import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { makeStyles } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { BASE_URL_API } from "variables/api";
import axios from "axios";
import Snackbar from "components/Snackbar/Snackbar";
import { useHistory } from "react-router-dom";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardFooter: {
    justifyContent: "center",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center",
  },
};

const useStyles = makeStyles(styles);

function Login() {
  const classes = useStyles();
  const { handleSubmit, control } = useForm();
  const ref1 = React.createRef();
  const ref2 = React.createRef();
  const [tc, setTC] = React.useState(false);
  const history = useHistory();

  const onLogin = (data) => {
    axios
      .post(`${BASE_URL_API}/login`, {
        username: data.username,
        password: data.password,
      })
      .then(({ data, status }) => {
        if (data.code === "SUCCESS") {
          localStorage.setItem("token", data.token);
          history.push("/admin/parti");
        } else {
          setTC(true);
        }
      });
  };

  const onError = (error) => {};

  return (
    <div className={classes.root}>
      <Snackbar
        place="tc"
        color="warning"
        message="Email or Password is wrong!"
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
      <GridItem xs={12} sm={12} md={4}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Login</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Controller
                  control={control}
                  name="username"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomInput
                      ref={ref1}
                      inputProps={field}
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomInput
                      ref={ref2}
                      inputProps={{
                        type: "password",
                        ...field,
                      }}
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button color="primary" onClick={handleSubmit(onLogin, onError)}>
              Login
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </div>
  );
}

export default Login;
