/* eslint-disable no-unused-vars */
import { IconButton } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/Inbox";
import { Button, Space } from "antd";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL_API } from "variables/api";
import PreviewModal from "./PreviewModal";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Progress() {
  const classes = useStyles();
  const history = useHistory();
  const viewRef = createRef();

  const [openView, setOpenView] = useState(false);
  const [table, setTable] = useState([]);
  const [page, setPage] = useState(1);
  const [selectRaw, setSelectRaw] = useState();

  const handleOpenView = (data) => {
    setOpenView(true);
    setSelectRaw(data);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const actionAfterConfirm = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    } else {
      axios
        .get(`${BASE_URL_API}/progress?page=${page}&limit=10`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data, status }) => {
          if (data.code === "SUCCESS") {
            performTable(data.data);
          } else {
            localStorage.removeItem("token");
            history.push("/login");
          }
        });
    }
  };

  const performTable = useCallback((data) => {
    const newFormat = data.map((item) => {
      const { user, km, created_at, progressImage, id } = item;
      const date = new Date(created_at).toGMTString();
      if (!user.isAdmin) {
        const uid = user.uid;
        const fullName = `${user.name} ${user.surname}`;
        return [
          uid,
          fullName,
          km,
          date,
          <IconButton
            aria-label="action"
            key={1}
            onClick={() =>
              handleOpenView({
                img: progressImage.url,
                km,
                date,
                uid: id,
                fullName,
              })
            }
            color="primary"
          >
            <InboxIcon />
          </IconButton>,
        ];
      }
    });
    setTable(newFormat);
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     history.push("/login");
  //   } else {
  //     axios
  //       .get(`${BASE_URL_API}/progress?page=${page}&limit=10`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then(({ data, status }) => {
  //         if (data.code === "SUCCESS") {
  //           performTable(data.data);
  //         } else {
  //           localStorage.removeItem("token");
  //           history.push("/login");
  //         }
  //       });
  //   }
  // }, [page]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Space style={{ marginBottom: 8 }}>
          <Button onClick={actionAfterConfirm}>Refresh</Button>
        </Space>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>UnConfirm Progress</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "uid",
                "ชื่อ",
                "ระยะทาง",
                "วันทีอัพโหลด",
                "การดำเนินการ",
              ]}
              tableData={table}
            />
          </CardBody>
        </Card>
      </GridItem>
      <PreviewModal
        ref={viewRef}
        open={openView}
        handleClose={handleCloseView}
        rawData={selectRaw}
        actionAfterConfirm={actionAfterConfirm}
      />
    </GridContainer>
  );
}
