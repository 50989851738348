/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Fade,
  FormControlLabel,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Image, Statistic } from "antd";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { Card as ACard } from "antd";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BASE_URL_API } from "variables/api";
import Meta from "antd/lib/card/Meta";
import Avatar from "antd/lib/avatar/avatar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // height: "100vh",
  },
  paper: {
    margin: theme.spacing(2, 2, 3),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    height: "500",
  },
  km: {
    color: theme.palette.primary.light,
  },
  kmBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  actionIcon: {
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

const PreviewModal = React.forwardRef(function PreviewModal(
  { handleClose, open, rawData, actionAfterConfirm },
  ref
) {
  const classes = useStyles();

  const [isApprove, setIsApprove] = useState(null);

  const handleChange = (e) => {
    setIsApprove(e.target.value === "true");
  };

  const onConfirm = () => {
    if (isApprove !== null) {
      const token = localStorage.getItem("token");
      if (!token) {
        history.push("/login");
      } else {
        axios
          .post(
            `${BASE_URL_API}/confirm-reject`,
            {
              uid: rawData.uid,
              isApprove,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(({ data, status }) => {
            handleClose();
            setIsApprove(null);
            actionAfterConfirm();
          });
      }
    }
  };

  return (
    <div ref={ref}>
      <Modal
        aria-labelledby="transition-modal-km"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card profile style={{ width: "100%" }} className={classes.paper}>
            <CardBody profile>
              <div className={classes.root}>
                {rawData && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "stretch",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <ACard>
                        <Meta title={rawData.fullName} />
                        <Statistic
                          title="ระยะทางที่วิ่ง"
                          value={rawData.km}
                          precision={3}
                          valueStyle={{ color: "#3f8600" }}
                          suffix={"km"}
                        />
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={isApprove}
                          style={{ display: "flex", flexDirection: "row" }}
                          onChange={(e) => handleChange(e)}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            // style={{ color: "white" }}
                            value={false}
                            label="Reject"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            // style={{ color: "white" }}
                            label="Confirm"
                            value={true}
                            labelPlacement="bottom"
                          />
                        </RadioGroup>
                      </ACard>
                    </div>
                    <Image width={350} src={rawData.img} preview={false} />
                  </>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={onConfirm}>
                Confirm Action
              </Button>
            </CardFooter>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
});

PreviewModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  rawData: PropTypes.object.isRequired,
  actionAfterConfirm: PropTypes.func.isRequired,
};

export default PreviewModal;
