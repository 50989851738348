/* eslint-disable no-unused-vars */
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Input, Pagination, Select, Space, Table } from "antd";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL_API } from "variables/api";
const { Search } = Input;

const pageSize = 10;

export default function Users() {
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [criteriaKey, setCriteriaKey] = useState("studentId");
  const history = useHistory();

  const columns = [
    {
      title: "รหัสนักศึกษา",
      width: 60,
      dataIndex: "studentId",
      key: "uid",
    },
    {
      title: "ชื่อ",
      dataIndex: "firstName",
      key: "1",
      width: 35,
      fixed: "left",
    },
    {
      title: "นามสกุล",
      dataIndex: "lastName",
      key: "1",
      width: 35,
      fixed: "left",
    },

    {
      title: "ชื่อพาน",
      dataIndex: "phanName",
      key: "2",
      width: 50,
    },
    {
      title: "รูปแบบพาน",
      dataIndex: "phanType",
      key: "4",
      width: 50,
    },
    {
      title: "อุปกรณ์ในการทำพาน",
      dataIndex: "ingredient",
      key: "3",
      width: 150,
    },
    {
      title: "คำอวยพร",
      dataIndex: "greetingText",
      key: "3",
      width: 150,
    },
    {
      title: "เวลาที่เข้าร่วม",
      dataIndex: "createdAt",
      key: "3",
      width: 150,
      render: (value) => {
        return new Date(value).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
      },
    },
  ];

  const callApiUser = useCallback((page = 1, limit = 10) => {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/login");
    } else {
      setIsLoading(true);
      axios
        .get(`${BASE_URL_API}/pati?page=${page}&limit=10`, {
          headers: {
            Authorization: token,
          },
        })
        .then(({ data, status }) => {
          if (data.code === "SUCCESS") {
            setCurrent(page);
            setTableData(data.data);
            setTotalItem(data.totalItem);
          } else {
            localStorage.removeItem("token");
            history.push("/login");
          }
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    callApiUser();
  }, []);

  const onChangeTable = (pagination) => {
    callApiUser(pagination);
  };

  const onSearch = (data) => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    } else {
      setIsLoading(true);
      axios
        .get(
          `${BASE_URL_API}/pati?${criteriaKey}=${data}&isSearch=true&page=1&limit=10`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(({ data, status }) => {
          if (data.code === "SUCCESS") {
            setCurrent(1);
            setTableData(data.data);
            setTotalItem(data.totalItem);
          } else {
            localStorage.removeItem("token");
            history.push("/login");
          }
          setIsLoading(false);
        });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Space style={{ marginBottom: 16 }}>
          <Input.Group compact>
            <Select
              defaultValue="studentId"
              value={criteriaKey}
              onChange={(value) => setCriteriaKey(value)}
            >
              <Option value="studentId">รหัสนักศึกษา</Option>
              <Option value="firstName">ชื่อ</Option>
              <Option value="lastName">นามสกุล</Option>
              <Option value="phanType">รูปแบบพาน</Option>
            </Select>
            <Search
              style={{ width: 400 }}
              placeholder="input search text"
              onSearch={onSearch}
              enterButton
            />
          </Input.Group>
        </Space>
        <Table
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={tableData}
          sticky
        />
        <Space style={{ marginTop: 16 }}>
          <Pagination
            onChange={onChangeTable}
            total={totalItem}
            current={current}
            pageSize={pageSize}
          />
        </Space>
      </GridItem>
    </GridContainer>
  );
}
