/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
/* eslint-disable no-unused-vars */
import Unarchive from "@material-ui/icons/Unarchive";
import Progress from "views/Progress/Progress";
import Users from "views/User/User";
import PeopleIcon from "@material-ui/icons/People";

const dashboardRoutes = [
  {
    path: "/parti",
    name: "ผู้ร่วมกิจกรรม",
    rtlName: "التطور للاحترافية",
    icon: PeopleIcon,
    component: Users,
    layout: "/admin",
  },
];

export default dashboardRoutes;
